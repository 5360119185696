import React from "react";
import Menu from "@components/menu";
import BgkRandom from "@components/bgkRandom";
import Footer from "@components/footer";

function Passion(){
  var classContentRight = BgkRandom("passion");

  return(

    <div className="container">
        <section className="left">
            <h2>Meglio 5 minuti in moto che una vita sprecata</h2>
            <article>
                <p>
                   CALCIO
                   Gioco a calcio praticamente da una vita, ho iniziato a correre dietro al pallone a circa 3 anni insieme a mio fratello.
                   Fino a 10 anni mi divertivo sotto casa con gli amici, poi ho iniziato a giocare un pò più seriamente.

                   Ho indossato le maglie delle seguenti squadre:

                   Algo: squadra a 7
                   San Carlo Milano: squadra a 7
                   Atletico Vittoria: squadra a 11 serie D
                   Giorgella: squadra a 11 serie C
                   Asi Monza: squadra a 11 serie B/C
                   Idrostar: squadra a 7

                   MOTO
                   Ho una Kawasaki ER-6N che fino ad oggi mi accompagna nella vita quotidiana come nei weekend per gite di piacere.

                   SNOW
                   Oltre al calcio e alla moto ho un altro hobby: lo snowboard, al quale purtroppo riesco a dedicare veramente poco tempo.
                </p>
            </article>
        </section>
        <Menu/>
        <section className={"right " + classContentRight}>
            <Footer/>
        </section>
     </div>
  );
}

export default Passion;