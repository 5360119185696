import React, { useState} from "react";
import Menu from "@components/menu";
import Footer from "@components/footer";
import Cliente from "@components/cliente";
import '@css/clienti.css';

function Portfolio(){
    const [clienti, setClienti] = useState([
        {
            "nome": "La Gazzetta dello Sport",
            "immagine": "gazzetta.jpg"
          },
          {
            "nome": "Gazzetta dello Sport - Speciale Elezioni 2013",
            "immagine": "elezioni.jpg"
          },
          {
            "nome": "Gazzetta dello Sport - Speciale Europei 2012",
            "immagine": "europei.jpg"
          },{
            "nome": "Gazzetta Fan News",
            "immagine": "gazzettafannews.jpg"
          },
          {
            "nome": "Gazzetta dello Sport - Speciale Giro d'Italia 2013",
            "immagine": "giroitalia.jpg"
          },
          {
            "nome": "Gazzetta dello Sport - Speciale Olimpiadi 2012",
            "immagine": "olimpiadi.jpg"
          },
          {
            "nome": "Gazzetta dello Sport - Speciale Paralimpiadi 2012",
            "immagine": "paralimpiadi.jpg"
          },
          {
            "nome": "Gazzetta dello Sport - Speciale Passione Motori",
            "immagine": "passionemotori.jpg"
          },
          {
            "nome": "Corriere della Sera",
            "immagine": "corriere.jpg"
          },
          {
            "nome": "Corriere Cucina",
            "immagine": "cucina.jpg"
          },
          {
            "nome": "Corriere - Economia Pro",
            "immagine": "economiapro.jpg"
          },
          {
            "nome": "Video Corriere",
            "immagine": "videocorriere.jpg"
          },
          {
            "nome": "Hotel Alcova Doge",
            "immagine": "alcova_doge.jpg"
          },
          {
            "nome": "Hotel Amazonia",
            "immagine": "amazonia.jpg"
          },
          {
            "nome": "Hotel Annapolis",
            "immagine": "annapolis.jpg"
          },
          {
            "nome": "Hotel Arrivederci Roma",
            "immagine": "arrivederciroma.jpg"
          },
          {
            "nome": "Hotel Astoria",
            "immagine": "astoria.jpg"
          },
          {
            "nome": "Hotel Bollina",
            "immagine": "bollina.jpg"
          },
          {
            "nome": "Hotel Borgo",
            "immagine": "borgo.jpg"
          },
          {
            "nome": "Hotel Brandoli",
            "immagine": "brandoli.jpg"
          },
          {
            "nome": "Hotel Clap",
            "immagine": "clap.jpg"
          },
          {
            "nome": "Hotel Don Prestige",
            "immagine": "don_prestige.jpg"
          },
          {
            "nome": "Hotel Du Pre",
            "immagine": "dupre.jpg"
          },
          {
            "nome": "Hotel Figli di Bacco",
            "immagine": "figlibacco.jpg"
          },
          {
            "nome": "Hotel Gallo d'Oro",
            "immagine": "gallodoro.jpg"
          },
          {
            "nome": "Hotel Leano",
            "immagine": "leano.jpg"
          },
          {
            "nome": "Hotel Lovely",
            "immagine": "lovely.jpg"
          },
          {
            "nome": "Hotel Manin",
            "immagine": "manin.jpg"
          },
          {
            "nome": "Hotel Marmorata",
            "immagine": "marmorata.jpg"
          },
          {
            "nome": "Hotel Miraparque",
            "immagine": "miraparque.jpg"
          },
          {
            "nome": "Hotel Mostyn",
            "immagine": "mostyn.jpg"
          },
          {
            "nome": "Hotel Royal Garden",
            "immagine": "royalgarden.jpg"
          },
          {
            "nome": "Hotel Semiramide",
            "immagine": "semiramide.jpg"
          },
          {
            "nome": "Hotel Val del Rei",
            "immagine": "valdelrei.jpg"
          },
          {
            "nome": "1000Models",
            "immagine": "1000models.jpg"
          },
          {
            "nome": "Anfima",
            "immagine": "anfima.jpg"
          },
          {
            "nome": "Bikestaff",
            "immagine": "bikestaff.jpg"
          },
          {
            "nome": "Cucina Professionale",
            "immagine": "cucinaprofessionale.jpg"
          },
          {
            "nome": "Don Lisander",
            "immagine": "donlisander.jpg"
          },
          {
            "nome": "Eritalia",
            "immagine": "eritalia.jpg"
          },
          {
            "nome": "Future Drive",
            "immagine": "futuredrive.jpg"
          },
          {
            "nome": "Gianni Dall'Aglio",
            "immagine": "giannidallaglio.jpg"
          },
          {
            "nome": "Kraft Foods Italia",
            "immagine": "kraftfoods.jpg"
          },
          {
            "nome": "Pub Matricola",
            "immagine": "matricola.jpg"
          },
          {
            "nome": "Photofoyer",
            "immagine": "photofoyer.jpg"
          },
          {
            "nome": "Plinio il Giovane",
            "immagine": "plinioilgiovane.jpg"
          },
          {
            "nome": "Real Casa",
            "immagine": "realcasa.jpg"
          },
          {
            "nome": "Solom",
            "immagine": "solom.jpg"
          },
          {
            "nome": "Somefi",
            "immagine": "somefi.jpg"
          },
          {
            "nome": "Tre 14 S.r.l.",
            "immagine": "tre14.jpg"
          }
    ]);

  return(
    <div className="container">
        <section className="left portfolio">
            <Menu/>
            <h2>Fai il lavoro che ti piace e non lavorerai nemmeno un giorno.</h2>

            <div className="clienti">
                {clienti.map(cliente => (
                    <Cliente name={`${cliente.nome}`} image={`${cliente.immagine}`} />
                ))}
            </div>

        </section>
        <Footer/>
     </div>
  );
}
export default Portfolio;