import React from "react";

function Footer(){
  return(
    <footer>
        <ul>
          <li className="email menu-item"><a href="mailto:piccininno.donatella@gmail.com">@</a></li>
          <li className="fb menu-item"><a href="https://www.facebook.com/Pichichi13" target="_blank">FB</a></li>
          <li className="tw menu-item"><a href="https://twitter.com/pichichi13" target="_blank">TW</a></li>
          <li className="in menu-item"><a href="https://www.linkedin.com/in/donatella-piccininno-00271624" target="_blank">LINKEDIN</a></li>
        </ul>
    </footer>
  );
}

export default Footer;