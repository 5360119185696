import '@css/App.css';
//import Menu from "@components/menu";
import About from "@components/about";
import Job from "@components/job";
import Portfolio from "@components/portfolio";
import Passion from "@components/passion";
import PageNotFound from "@components/pageNotFound";
import PaginaIncrementa from "@components/paginaIncrementa";
import PaginaFetch from "@components/paginaFetch";
import PaginaFetchArray from "@components/paginaFetchArray";
import PaginaFetchDettaglio from "@components/paginaFetchDettaglio";
//import Footer from "@components/footer";
import {HashRouter, Switch, Route} from 'react-router-dom';

function App() {

  return (
    <HashRouter>
        <Switch>
            <Route exact path="/" component={About} />
            <Route exact path="/about" component={About} />
            <Route exact path="/job" component={Job} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/passion" component={Passion} />
            <Route component={PageNotFound} />

            <Route exact path="/paginaIncrementa" component={PaginaIncrementa} />
            <Route exact path="/paginaFetch" component={PaginaFetch} />
            <Route exact path="/paginaFetchArray" component={PaginaFetchArray} />
            <Route exact path="/:id" component={PaginaFetchDettaglio} />
        </Switch>
    </HashRouter>
  );
}

export default App;
