var BgkRandom = (page) =>{
  //console.log("Page"+page);
  var numberImage;
  var classToAdd;

  //Genera numero casuale
  var numberRandom = (min,max) =>{
    //console.log(Math.floor(Math.random() * (max - min)) + min);
    numberImage = Math.floor(Math.random() * (max - min)) + min;
  }

  //Crea la classe in base al numero random generato
  var bgkRight = () =>{
      numberRandom(0,8);
      classToAdd = page + numberImage;
  }

   bgkRight();
   //console.log(classToAdd);
   return classToAdd;
}

export default BgkRandom;