import React, { useState } from "react";

//ESEMPIO CON GLI HOOKS (permettono di gestire lo state anche con una funzione, prima si poteva solo con le classi)
function PaginaIncrementa(){
  const [count, setCount] = useState(0);
  const increment = () => {
    setCount(count + 1);
  }

  return(
    <div className="container">
        counter: {count}
        <button onClick={increment}>Incrementa</button>
     </div>
  );
}
export default PaginaIncrementa;

//ESEMPIO CON CLASS USATO PRIMA DEGLI HOOKS
/*class PaginaIncrementa extends React.Component{
  state = {
      number: 0
  }

  incrementa = () => {
    this.setState({
        number: this.state.number + 1
    })
  }

  render(){
      return(
        <div className="container">
            counter: {this.state.number}
            <button onClick={this.incrementa}>Incrementa</button>
         </div>
      );
  }
}
export default PaginaIncrementa;*/