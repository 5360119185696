//useEffect serve per lanciare una funzione al caricamento della pagina
import React, { useState} from "react";

function PaginaFetchArray(){

    const [clienti, setClienti] = useState([
        {name: 'La Gazzetta dello Sport', link: 'https://www.gazzetta.it', image: '1.jpg'},
        {name: 'Gazzetta Fan News', link: 'https://www.gazzetta.it', image: '1.jpg'}
    ]);


  return(
    <div className="container">
        {clienti.map(cliente => (
            <div class="cliente">
                <h4 key={cliente.id}>
                    <a href={`${cliente.link}`} target="_blank"><img src={`${cliente.image}`} />{cliente.name}</a>
                </h4>
            </div>
        ))}
    </div>
  );
}
export default PaginaFetchArray;