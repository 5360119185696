//useEffect serve per lanciare una funzione al caricamento della pagina
import React, { useState, useEffect } from "react";

function PaginaFetchDettaglio(props){
    useEffect(() => {
        fetchData();
    }, [])

    const [post, setPost] = useState({});

    const fetchData = async () => {
        const id = props.match.params.id;
        const rawData = await fetch(`https://jsonplaceholder.typicode.com/posts/${id}`)
        const post = await rawData.json();
        console.log(post);
        setPost(post);
    }


  return(
    <div className="container">
        <h4>{post.title}</h4>
    </div>
  );
}
export default PaginaFetchDettaglio;