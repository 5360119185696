import React from "react";
import Menu from "@components/menu";
import BgkRandom from "@components/bgkRandom";
import Footer from "@components/footer";

function About(){
    var classContentRight = BgkRandom("about");
    //console.log("RITORNA"+classContentRight);

  return(
    <div className="container">
        <section className="left">
            <h2>Si volge ad attendere il futuro solo chi non sa vivere il presente</h2>
            <article>
                <p>
                    Ciao a tutti, mi presento molto in breve......sono una ragazza nata il 6 luglio dell'82, timida, lunatica, dolce e un pò fuori di testa.
                    Lavoro come Front End Developer da più di 10 anni e gioco a calcio praticamente dalla nascita.
                    Adoro le moto, soprattutto la mia Errina (Kawasaki ER-6N) e mi diverto facendo salti con lo snowboard.
                </p>
            </article>
        </section>
        <Menu/>
        <section className={"right " + classContentRight}>
            {/*<img src={image1} />*/}
            <Footer/>
        </section>
     </div>
  );
}

export default About;