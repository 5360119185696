import React from "react";
import Menu from "@components/menu";
import Footer from "@components/footer";

function Job(){
  return(

    <div className="container">
        <section className="left">
            <h2>Non ho paura dei computer, ma della loro eventuale mancanza</h2>
            <article>
                <p>
                   Sono una Front-end Developer da più di 10 anni; ho lavorato per piccole e grandi aziende, ma anche per privati.

                   Ho affrontato progetti singolarmente ma anche in team.

                   Ho lavorato presso le seguenti società:
                   Know IT s.r.l.
                   Datalog s.r.l.
                   Tre14 s.r.l.
                   Qwentes s.r.l.
                   Eidon s.r.l. (Presso RCS Mediagroup)

                   Attualmente:
                   Assunta presso Eidon s.r.l. come consulente per RCS Mediagroup (gestione sito Gazzetta.it e Corriere.it, creazione App del Corriere della Sera, sia su dispositivi Android che IOS)

                   Linguaggi di programmazione:
                   ionic - angularjs - compass - sass - html5 - html - shtml - dhtml - css3 - css - javascript - jquery - visual basic - actionscript - asp - xml - php - pascal - cobol

                   Database:
                   Access - PhpMyAdmin - SQLServer

                   Altro:
                   Windows - Mac Os - Pacchetto Microsoft Office - Pacchetto Macromedia - Adobe Photoshop - Svn

                   -> <a href="CVPiccininnoDonatella.pdf" className="link" download><span>SCARICA CV</span></a>
                </p>
            </article>
        </section>
        <Menu/>
        <section className="right job">
            <Footer/>
        </section>
     </div>
  );
}

export default Job;