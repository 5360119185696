import React from "react";
import {NavLink} from 'react-router-dom';

function Menu(){
    return(
        <section className="menu">
            <div id="menu_principale">
               <NavLink to="/about" className="link" activeClassName="active"><span>Me</span></NavLink>
               <NavLink to="/job" className="link" activeClassName="active"><span>Job</span></NavLink>
               <NavLink to="/portfolio" className="link" activeClassName="active"><span>Portfolio</span></NavLink>
               <a href="CVPiccininnoDonatella.pdf" className="link" download><span>CV</span></a>
               {/*<NavLink to="CVPiccininnoDonatella.pdf" className="link" download><span>CV</span></NavLink>*/}
               <NavLink to="/passion" className="link" activeClassName="active"><span>Passion</span></NavLink>
           </div>
        </section>
    );
}

export default Menu;