//useEffect serve per lanciare una funzione al caricamento della pagina
import React, { useState, useEffect } from "react";
import {NavLink} from 'react-router-dom';

function PaginaFetch(){
    useEffect(() => {
        fetchData();
    }, [])

    const [posts, setPosts] = useState([]);

    const fetchData = async () => {
        const rawData = await fetch('https://jsonplaceholder.typicode.com/posts')
        const data = await rawData.json();
        console.log(data.slice(0, 10));
        const posts = data.slice(0,10);
        setPosts(posts);
    }



  return(
    <div className="container">

        {posts.map(post => (
            <NavLink to={`/${post.id}`}>
                <h4 key={post.id}>{post.title}</h4>
            </NavLink>
        ))}

    </div>
  );
}
export default PaginaFetch;