import React from "react";
import Menu from "@components/menu";
import Footer from "@components/footer";

function PageNotFound(){

  return(
    <div className="container">
        <section className="left">
            <article>
                <p>
                    Ops....Pagina non trovata :(
                </p>
            </article>
        </section>
        <Menu/>
        <section className="right">
            <Footer/>
        </section>
     </div>
  );
}

export default PageNotFound;