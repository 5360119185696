import React from "react";

function Cliente(props){
console.log(props);
console.log("NAME"+props.name);
console.log("IMAGE"+props.image);
  return(
    <div className="cliente">
        <h2>{`${props.name}`}</h2>
        <img src={`https://www.donatellapiccininno.it/temp/portfolio/${props.image}`} />
    </div>
  );
}
export default Cliente;